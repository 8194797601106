.about {
  margin-top: 280px;
}

.text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.description {
  text-align: center;
  width: 60%;
  text-align: center;
  font-size: 30px;
  margin-bottom: 140px;
}

.top-text {
  font-size: 50px;
  font-weight: 600;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .description {
     width: 80%;
  }

  .about {
    margin-top: 120px;
  }

  .description {
     margin-bottom: 80px;
     width: 80%;
  }
}
