.team {
  margin-bottom: 100px;
}

.team-title {
  text-align: center;
  font-size: 60px;
  margin-top: 120px;
  font-weight: 600;
}

.team-img {
  width: 350px;
  height: 350px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #03fca1;
  transition: all 0.5s ease;
}

.team-img:hover {
  transform: scale(1.01);
}

.info {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.team-imgs {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.img {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.team-name {
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  border-bottom: 1px solid white;
}

.team-desc {
   margin: 0;
   font-size: 30px;
   font-weight: 0;
}

.icons {
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 50px;
}

.icon {
  margin: 7px;
  cursor: pointer;
}

.icon:hover, .team-name:hover, .team-desc:hover {
  color: #03fc9d;
}

hr {
  width: 100;
  border: 2px dashed #03fc9d;
}

.hr-pink {
  width: 100;
  border: 3px solid #ff17d8;
}

.hr-top  {
  margin-top: 140px;
}

/* .first, .third {
  border: 2px solid #03fc9d;
}

.second {
  border: 2px solid #fc03d2;
} */

.boyo {
  display: none;
}

.secondimg {
  display: none;
}


@media only screen and (max-width: 1000px) {
  .team-img {
    height: auto;
    width: 60%;
  }

  .img {
    /* gap: 10px; */
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .secondimg {
    display: block;
  }

  .firstimg {
    display: none;
  }

  .icon {
    /* margin-bottom: 50px; */
  }

  .team {
    margin-bottom: 30px;
  }

  .team-imgs {
    flex-wrap: nowrap;
  }

  .team-title {
    margin-top: 60px;
  }

  .info {
    width: auto;
  }

  .three {
    margin-top: 50px;
  }

  .hr-top {
    margin-top: 80px;
  }
}
