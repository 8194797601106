.footer {
  border-top: 1px solid white;
}

.email {
  text-align: center;
  font-size: 25px;
}

.email:hover {
  color: #03fc9d;
}

.foot {
  text-align: center;
  font-size: 20px;
  margin: 20px;
}

.insta {
  margin-bottom: 0;
}
