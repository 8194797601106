body {
  background-color: black;
  color: white;
  font-family: 'Darker Grotesque', sans-serif;
}

.app {

}

a {
  text-decoration: none;
  color: white;
}
