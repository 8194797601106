.main-nft {
  margin-top: 40px;
  width: 100%;
  height: 500px;
  text-align: center;
}

.main-logo {
  height: 140px;
  margin-top: 20px;
}

.nft {
  width: auto;
  height: 400px;
  margin: 5px;
  object-fit: cover;
  margin-bottom: 15px;
}

.images {
  display: flex;
  align-items: flex-end;
  width: 100%;
  overflow-x: scroll;
  margin-top: 80px;
}

.first-release {
  display: flex;
  justify-content: center;
}

.description-release {
  width: 60%;
  font-size: 30px;
  margin-bottom: 100px;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
  height: 15px;

}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #03fca1;
}

.punkidz {
  font-family: 'New Rocker', cursive;
  font-size: 60px;
}

.meet-the {
  font-size: 40px;
  text-align: center;
  margin: 140px 0 0 0;
}

.alert {
  font-size: 40px;
  /* border-bottom: 2px solid #03fca1; */
  padding: 5px 15px;
  color: #03fca1;
}

@media only screen and (max-width: 600px) {
  .main-logo {
     height: 90px;
  }

  .description-release {
    width: 85%;
    margin-bottom: 50px;
  }

  .nft {
    width: auto;
    height: 300px;
  }

  .alert {
    font-size: 38px;
  }
}
