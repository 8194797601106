.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(252, 3, 210, 0.5) 0px 5px, rgba(252, 3, 210, 0.3) 0px 10px, rgba(252, 3, 210, 0.2) 0px 15px, rgba(252, 3, 210, 0.1) 0px 20px, rgba(252, 3, 210, 0.05) 0px 25px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.8)
  /* background-image: url('CoverPhoto.jpg') ;
  background-position: bottom;
  background-size: cover; */
}

.left-nav {
  padding: 10px 30px;
}

.right-nav {
  display: flex;
  font-size: 30px;
  padding-right: 30px;
}

.glitch {
  cursor: pointer;
  margin: 0;
  text-decoration: none;
  color: white;
  transition: all 0.5s ease;
  margin-left: 20px;
}

.punkkidz-img {
  height: 50px;
}

.glitch:hover {
  /* text-shadow:-1px -1px 0 cyan, 1px 1px 0 magenta; */
  color: #03fca1;
  transform: scale(1.05);
}

.open-link {
  display: none;
}

.sea-nav {
  display: none;
}

.opensea-div {
  display: none;
}

@media only screen and (max-width: 600px) {
  .right-nav {
    display: none;
  }

  .navbar {
    /* justify-content: center; */
  }

  .punkkidz-img {
    height: 40px;
  }

  .open-link {
    display: block;
    text-align: right;
    font-size: 28px;
    margin-right: 35px;
    margin-left: 0;
  }

  .sea-nav {
    display: block;
    height: 30px;
    width: 30px;
  }

  .opensea-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
